<!--  -->
<template>
    <div class="homeView">
        <div class="homeView_l">
            <NavBar></NavBar>
        </div>
        <div class="homeView_r">
            <Header></Header>
            <AppView></AppView>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header';
import NavBar from '@/components/Nav';
import AppView from '@/views/appmin';
export default {
    name: 'homeView',
    components: { NavBar, AppView, Header },
    props: {},
    data() {
        return {
        };
    },

    computed: {},

    watch: {
        
    },

    created() { },

    mounted() { },

    methods: {},
}

</script>

<style scoped>
.homeView {
    display: flex;
    padding-left: 200px;
}

.homeView_l {
    position: fixed;
    left: 0;
    top: 0;
    width: 200px;
    z-index: 999;
   
}

.homeView_r {
    width: 100%;
}</style>