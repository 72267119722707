<!--  -->
<template>
    <div class="navView">
        
        <div class="logo"><img src="../../assets/images/logo.png" alt="">
            <h1>GO柄管理</h1>
        </div>
        <el-menu default-active='menu0' class="el-menu-vertical-demo" :router="true" background-color="rgba(0,0,0,0)"
            text-color="#fff" active-text-color="#fff" :unique-opened="true" :collapse="isCollapse">
            <el-submenu v-for="(item, i) in navData" :key="i" :index="`menu${i}`" v-show="!item.hidden">
                <template slot="title">
                    <i :class="item.meta.icon"></i>
                    <span>{{ item.meta.title }}</span>
                </template>
                <el-menu-item-group v-if="item.children">
                    <el-menu-item v-show="!c_item.hidden" v-for="(c_item, c_i) in item.children" :key="c_i"
                        :index="`menu_${i}_${c_i}`" :route="{ path:`${item.path}/${c_item.path}` }">
                        <i :class="c_item.meta.icon"></i>
                        <span>{{ c_item.meta.title }} </span>
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
        </el-menu>
    </div>
</template>

<script>
export default {
    name: 'navView',
    components: {},
    props: {},
    data() {
        return {
            navData: this.$router.options.routes,
            isCollapse: false
        };
    },

    computed: {},

    watch: {},

    created() { },

    mounted() { 
    },

    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        }
    },
}

</script>

<style scoped>
.navView {
    padding: 10px;
    height: 100vh;
    overflow: auto;
    background-image: -webkit-linear-gradient(0deg, #2f25b8 0%, #0c5bba 100%)
}

.navView .logo {
    display: flex;
    align-items: center;
    color: #333;
    background: #fff;
    border-radius: 6px;
    padding: 6px;
}

.navView .logo h1 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 0 10px;
}

.navView .logo img {
    height: 40px;
}</style>