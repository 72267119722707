<!--  -->
<template>
    <div class="header">
        <span></span>
        <!-- <div class="logo"><img src="../../assets/images/logo.png" alt=""></div> -->
        <div class="header_user">
            <el-dropdown trigger="click"  @command="handleCommand">
                <div class="header_user_top el-dropdown-link">
                    <img src="../../assets/images/head.png" alt="">
                    <i class="el-icon-caret-bottom"></i>
                </div> 
                <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item icon="el-icon-user">个人中心</el-dropdown-item> -->
                    <el-dropdown-item icon="el-icon-circle-close" command="logOut">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
export default {
    name: 'headerView',
    components: {},
    props: {},
    data() {
        return {
        };
    },

    computed: {},

    watch: {},

    created() { },

    mounted() { },

    methods: {
        logOut(){
            console.log(222)
            localStorage.setItem('token', '')
            this.$router.go(0);
           
        },
        handleCommand(command){
            this[command]()
        }
    },
}

</script>

<style scoped>
.header {
    position: fixed;
    z-index: 999;
    width:calc(100% - 200px);
    padding: 0 20px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    background:#fff;
    box-shadow: 0 0 6px #dfdfdf;
}
.header_user_top{display: flex; align-items: center;cursor: pointer;}
.header_user_top img{border-radius: 50%; box-shadow: 0 0 6px #dfdfdf;}
.header_user_top i{margin-left: 8px;color: #999;}
</style>