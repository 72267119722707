<template>
  <div id="app">

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="key"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="key"/>
  </div>
</template>
<script>


import "@/assets/css/style.css";
// import "@/assets/iconfont/iconfont.css"; 
export default {
  name: 'App',
  components: {},
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  mounted() {    
  }
}
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
}
</style>
