import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: HomeView,
    hidden: false,
    meta: { title: '首页', icon: 'el-icon-s-home', noCache: true },
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/'),
        name: 'dashboard',
        hidden: false,
        meta: { title: '首页', icon: 'el-icon-s-home', noCache: true }

      }
    ]
  },
  {
    path: '/logIn',
    component: () => import('@/views/logIn/'),
    name: 'logIn',
    hidden: true,
    meta: { title: '登录', icon: 'el-icon-s-home', noCache: true }
  },
  // 管理员
  {
    path: '/admin',
    redirect: '/admin/index',
    component: HomeView,
    hidden: false,
    meta: { title: '管理员', icon: 'el-icon-user-solid', noCache: true },
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/'),
        name: 'adminList',
        meta: { title: '管理员列表', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'edit',
        component: () => import('@/views/admin/edit'),
        name: 'adminEdit',
        hidden: true,
        meta: { title: '管理员设置', icon: 'el-icon-menu', noCache: true }
      },
      {
        path: 'barnd',
        component: () => import('@/views/admin/'),
        name: 'adminBarndList',
        meta: { title: '品牌管理员', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'barndEdit',
        component: () => import('@/views/admin/edit'),
        name: 'adminBarndEdit',
        hidden: true,
        meta: { title: '管理员设置', icon: 'el-icon-menu', noCache: true }
      },
    ]
  },
  // 用户管理
  {
    path: '/user',
    redirect: '/user/index',
    component: HomeView,
    hidden: false,
    meta: { title: '用户管理', icon: 'el-icon-user-solid', noCache: true },
    children: [
      {
        path: 'index',
        component: () => import('@/views/user/'),
        name: 'adminList',
        meta: {
          title: '用户列表', icon: 'el-icon-s-order', noCache: true, keepAlive: true // 缓存该页面
        }
      },
      {
        path: 'edit',
        component: () => import('@/views/user/edit'),
        name: 'adminEdit',
        hidden: true,
        meta: { title: '用户设置', icon: 'el-icon-menu', noCache: true }
      },

    ]
  },
  // 系统消息管理
  {
    path: '/sysMsg',
    redirect: '/sysMsg/index',
    component: HomeView,
    hidden: false,
    meta: { title: '系统消息管理', icon: 'el-icon-user-solid', noCache: true },
    children: [
      {
        path: 'index',
        component: () => import('@/views/sysmessage/'),
        name: 'adminList',
        meta: {
          title: '消息列表', icon: 'el-icon-s-order', noCache: true, keepAlive: true // 缓存该页面
        }
      },
      {
        path: 'edit',
        component: () => import('@/views/sysmessage/edit'),
        name: 'adminEdit',
        hidden: true,
        meta: { title: '系统设置', icon: 'el-icon-menu', noCache: true }
      },
      {
        path: 'push',
        component: () => import('@/views/sysmessage/push'),
        name: 'adminList',
        meta: {
          title: '消息推送', icon: 'el-icon-s-order', noCache: true, keepAlive: true // 缓存该页面
        }
      },
    ]
  },

  // banner
  {
    path: '/banner',
    redirect: '/banner/index',
    component: HomeView,
    hidden: false,
    meta: { title: '首页轮播', icon: 'el-icon-menu', noCache: true },
    children: [
      {
        path: 'index',
        component: () => import('@/views/banner/'),
        name: 'bannerList',
        meta: { title: '轮播列表', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'edit',
        component: () => import('@/views/banner/edit'),
        name: 'bannerEdit',
        hidden: true,
        meta: { title: '轮播编辑', icon: 'el-icon-menu', noCache: true }
      }
    ]
  },
  // 排行榜
  {
    path: '/Top10',
    component: HomeView,
    redirect: '/Top10/index',
    hidden: false,
    meta: { title: '排行榜', icon: 'el-icon-s-marketing', noCache: true },
    children: [
      {
        path: 'index',
        component: () => import('@/views/Top10/'),
        name: 'new',
        meta: { title: '新品排行榜', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'hot',
        component: () => import('@/views/Top10/'),
        name: 'hot',
        meta: { title: '飙升排行榜', icon: 'el-icon-s-order', noCache: true }
      }
    ]
  },
  // 手柄
  {
    path: '/gamepad',
    redirect: '/gamepad/list',
    component: HomeView,
    meta: { title: '手柄管理', icon: 'el-icon-menu', noCache: true },
    children: [
      {
        path: 'list',
        component: () => import('@/views/gamepad/gamepadList/'),
        name: 'gamepadList',

        meta: { title: '手柄列表', icon: 'el-icon-menu', noCache: true },

      },
      // 手柄编辑
      {
        path: 'detail',
        redirect: 'detail/base',
        component: () => import('@/views/gamepad/gamepadDetail/'),
        name: 'gamepadDetail',
        hidden: true,
        meta: { title: '手柄详情', icon: 'el-icon-menu', noCache: false },
        children: [
          {
            path: 'base',
            component: () => import('@/views/gamepad/gamepadDetail/base'),
            name: 'gamepadDetailBase',
            meta: { title: '基础信息', icon: 'el-icon-menu', noCache: true }

          },
          {
            path: 'images',
            component: () => import('@/views/gamepad/gamepadDetail/images'),
            name: 'gamepadDetailImages',
            meta: { title: '图组设置', icon: 'el-icon-menu', noCache: true }

          },
          {
            path: 'radar',
            component: () => import('@/views/gamepad/gamepadDetail/radar'),
            name: 'gamepadDetaiRadar',
            meta: { title: '六芒星设置', icon: 'el-icon-menu', noCache: true }

          },
          {
            path: 'params',
            component: () => import('@/views/gamepad/gamepadDetail/params'),
            name: 'gamepadDetaiParams',
            meta: { title: '参数设置', icon: 'el-icon-menu', noCache: true }

          },
          {
            path: 'key',
            component: () => import('@/views/gamepad/gamepadDetail/key'),
            name: 'gamepadDetaiKey',
            meta: { title: '按键力度设置', icon: 'el-icon-menu', noCache: true }

          },
          {
            path: 'related',
            component: () => import('@/views/gamepad/gamepadDetail/related'),
            name: 'gamepadDetaiPeriphery',
            meta: { title: '关联设置', icon: 'el-icon-menu', noCache: true }

          },
          {
            path: 'check',
            component: () => import('@/views/gamepad/gamepadDetail/searchCheck'),
            name: 'gamepadDetaiPeriphery',
            meta: { title: '筛选设置', icon: 'el-icon-menu', noCache: true }

          },
          {
            path: 'games',
            component: () => import('@/views/gamepad/gamepadDetail/games'),
            name: 'gamepadDetaiGames',
            meta: { title: '推荐游戏', icon: 'el-icon-menu', noCache: true }

          },

          {
            path: 'factory',
            component: () => import('@/views/gamepad/gamepadDetail/factory'),
            name: 'gamepadDetaiPeriphery',
            meta: { title: '工厂/方案商关联', icon: 'el-icon-menu', noCache: true }

          },
        ]
      },
      // 筛选分类
      {
        path: 'searchType',
        component: () => import('@/views/gamepad/searchTypes/'),
        name: 'searchType',
        hidden: false,
        meta: { title: '筛选分类', icon: 'el-icon-menu', noCache: true },

      },
      {
        path: 'searchTypeEdit',
        hidden: true,
        component: () => import('@/views/gamepad/searchTypes/edit.vue'),
        name: 'searchTypeEdit',
        meta: { title: '筛选分类编辑', icon: 'el-icon-menu', noCache: true }
      },
      {
        path: 'searchChildren',
        hidden: true,
        component: () => import('@/views/gamepad/searchTypes/children.vue'),
        name: 'searchTypeChildren',
        meta: { title: '筛选分类编辑', icon: 'el-icon-menu', noCache: true }
      },
      {
        path: 'childrenEdit',
        hidden: true,
        component: () => import('@/views/gamepad/searchTypes/childrenEdit.vue'),
        name: 'searchTypeChildren',
        meta: { title: '筛选分类编辑', icon: 'el-icon-menu', noCache: true }
      },
      // 百元推荐
      {
        path: 'recommend',
        component: () => import('@/views/gamepad/recommend'),
        name: 'gamepadDetaiRecommend',
        meta: { title: '百元推荐', icon: 'el-icon-menu', noCache: true }

      },
    ]
  },
  // 文章
  {
    path: '/article',
    redirect: '/article/list',
    component: HomeView,
    hidden: false,
    meta: { title: '文章管理', icon: 'el-icon-document', noCache: true },
    children: [
      {
        path: 'list',
        component: () => import('@/views/article/articleList'),
        name: 'article',
        meta: { title: '拆解列表', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'detail',
        redirect: 'detail/base',
        component: () => import('@/views/article/articleDetail'),
        name: 'article',
        hidden: true,
        meta: { title: '拆解文章', icon: 'el-icon-s-order', noCache: true },
        children: [
          {
            path: 'base',
            component: () => import('@/views/article/articleDetail/base'),
            name: 'article',
            meta: { title: '基础信息', icon: 'el-icon-menu', noCache: true }

          },
          {
            path: 'related',
            component: () => import('@/views/article/articleDetail/related'),
            name: 'article',
            meta: { title: '关联设置', icon: 'el-icon-menu', noCache: true }

          }
        ]
      },
    ]
  },
  // 新闻
  {
    path: '/news',
    redirect: '/news/type',
    component: HomeView,
    hidden: false,
    meta: { title: '新闻管理', icon: 'el-icon-news', noCache: true },
    children: [
      {
        path: 'type',
        component: () => import('@/views/news/types'),
        name: 'news',
        meta: { title: '新闻分类', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'typeEdit',
        component: () => import('@/views/news/types/edit'),
        name: 'news',
        hidden: true,
        meta: { title: '新闻分类编辑', icon: 'el-icon-s-order', noCache: true }

      },
      {
        path: 'list',
        component: () => import('@/views/article/articleList'),
        name: 'news',
        hidden: true,
        meta: { title: '新闻列表', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'detail',
        redirect: 'detail/base',
        component: () => import('@/views/article/articleDetail'),
        name: 'news',
        hidden: true,
        meta: { title: '新闻文章', icon: 'el-icon-menu', noCache: true },
        children: [
          {
            path: 'base',
            component: () => import('@/views/article/articleDetail/base'),
            name: 'news',
            meta: { title: '基础信息', icon: 'el-icon-menu', noCache: true }

          },
          {
            path: 'related',
            component: () => import('@/views/article/articleDetail/related'),
            name: 'news',
            meta: { title: '关联设置', icon: 'el-icon-menu', noCache: true }

          }
        ]
      },
    ]
  },
  // 品牌
  {
    path: '/brand',
    redirect: '/brand/type',
    component: HomeView,
    hidden: false,
    meta: { title: '品牌管理', icon: 'el-icon-s-cooperation', noCache: true },
    children: [
      {
        path: 'type',
        component: () => import('@/views/brand/types'),
        name: 'brand',
        meta: { title: '品牌列表', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'typeEdit',
        component: () => import('@/views/brand/types/edit'),
        name: 'news',
        hidden: true,
        meta: { title: '品牌编辑', icon: 'menu', noCache: true }

      },
      {
        path: 'list',
        component: () => import('@/views/brand/list'),
        name: 'brand',
        hidden: true,
        meta: { title: '品牌系列', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'seriesEdit',
        component: () => import('@/views/brand/list/edit'),
        name: 'brand',
        hidden: true,
        meta: { title: '品牌系列', icon: 'el-icon-s-order', noCache: true }
      },
    ]
  },
  // 评论列表
  {
    path: '/comment',
    redirect: '/comment/index',
    component: HomeView,
    hidden: false,
    meta: { title: '评论管理', icon: 'el-icon-chat-line-square', noCache: true },
    children: [
      {
        path: 'index',
        redirect: '/comment/index/gamepad',
        component: () => import('@/views/comment/'),
        name: 'comment',
        meta: { title: '评论列表', icon: 'el-icon-s-order', noCache: true },
        children: [
          {
            path: 'gamepad',
            component: () => import('@/views/comment/comment.vue'),
            name: 'gamepad',
            meta: { title: '手柄评论列表', icon: 'menu', noCache: true },
            children: [

            ]
          },
          {
            path: 'disassemble',
            component: () => import('@/views/comment/comment.vue'),
            name: 'disassemble',
            meta: { title: '文章评论列表', icon: 'menu', noCache: true }
          },
          {
            path: 'activity',
            component: () => import('@/views/comment/comment.vue'),
            name: 'activity',
            meta: { title: '活动评论列表', icon: 'menu', noCache: true }
          },
          {
            path: 'GoQuan',
            component: () => import('@/views/comment/comment.vue'),
            name: 'Forum',
            meta: { title: 'Go圈评论', icon: 'menu', noCache: true }
          },
          {
            path: 'test',
            component: () => import('@/views/comment/comment.vue'),
            name: 'Evaluation',
            meta: { title: '测评评论', icon: 'menu', noCache: true }
          },
          {
            path: 'reply',
            component: () => import('@/views/comment/reply'),
            name: 'reply',
            hidden: true,
            meta: { title: '评论回复', icon: 'menu', noCache: true }
          },
          {
            path: 'replyAll',
            component: () => import('@/views/comment/replyAll'),
            name: 'reply',
            hidden: true,
            meta: { title: '评论回复上下文', icon: 'menu', noCache: true }
          },
        ]
      },

    ]
  },
  // 留言信息
  {
    path: '/message',
    redirect: '/message/index',
    component: HomeView,
    hidden: false,
    meta: { title: '留言信息', icon: 'el-icon-chat-line-round', noCache: true },
    children: [
      {
        path: 'index',
        component: () => import('@/views/message/'),
        name: 'message',
        meta: { title: '产品合作', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'opinion',
        component: () => import('@/views/message/opinion.vue'),
        name: 'message',
        meta: { title: '建议反馈', icon: 'el-icon-s-order', noCache: true }
      }

    ]
  },
  // 兑换管理
  {
    path: '/shop',
    redirect: '/shop/list',
    component: HomeView,
    hidden: false,
    meta: { title: '兑换管理', icon: 'el-icon-s-goods', noCache: true },
    children: [
      {
        path: 'list',
        component: () => import('@/views/shop/list'),
        name: 'shopList',
        meta: { title: '兑换物品列表', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'edit',
        component: () => import('@/views/shop/edit.vue'),
        name: 'shopList',
        hidden: true,
        meta: { title: '兑换物品设置', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'exchange',
        component: () => import('@/views/shop/exchange.vue'),
        name: 'shopList',
        meta: { title: '兑换列表', icon: 'el-icon-s-order', noCache: true }
      },
    ]
  },
  // 抽奖管理
  {
    path: '/raffle',
    redirect: '/raffle/list',
    component: HomeView,
    hidden: false,
    meta: { title: '抽奖管理', icon: 'el-icon-present', noCache: true },
    children: [
      {
        path: 'list',
        component: () => import('@/views/raffle/list'),
        name: 'raffleList',
        meta: { title: '奖品列表', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'edit',
        component: () => import('@/views/raffle/edit.vue'),
        name: 'raffleEdit',
        hidden: true,
        meta: { title: '奖品设置', icon: 'el-icon-menu', noCache: true }
      },
      {
        path: 'exchange',
        component: () => import('@/views/raffle/exchange.vue'),
        name: 'raffleList',
        meta: { title: '奖品兑换', icon: 'el-icon-s-order', noCache: true }
      }
    ]
  },

  // 审核管理
  {
    path: '/examine',
    redirect: '/examine/list',
    component: HomeView,
    hidden: false,
    meta: { title: '审核管理', icon: 'el-icon-s-check', noCache: true },
    children: [
      {
        path: 'list',
        redirect: '/examine/list/activity',
        component: () => import('@/views/examine/index'),
        name: 'examine',
        meta: { title: '审核列表', icon: 'el-icon-s-order', noCache: true ,keepAlive:true},
        children: [
          {
            path: 'activity',
            component: () => import('@/views/examine/list'),
            name: 'examineActivity',
            meta: { title: '活动审核', noCache: true, brand_id: -1, type: 'activity', }
          },
          {
            path: 'news',
            component: () => import('@/views/examine/list'),
            name: 'examineNews',
            meta: { title: '新闻审核', noCache: true, brand_id: -1, type: 'news', }
          },
          {
            path: 'forum',
            component: () => import('@/views/examine/list'),
            name: 'examineForum',
            meta: { title: '圈子审核', noCache: true, brand_id: 0, type: 'forum', }
          }
        ]
      },
      {
        path: 'edit',
        component: () => import('@/views/examine/edit.vue'),
        name: 'examine',
        hidden: true,
        meta: { title: '审核', icon: 'el-icon-menu', noCache: true }
      },

    ]
  },
  // 活动管理
  {
    path: '/activity',
    redirect: '/activity/list',
    component: HomeView,
    hidden: false,
    meta: { title: '活动管理', icon: 'el-icon-goods', noCache: true },
    children: [
      {
        path: 'list',
        component: () => import('@/views/activity/index'),
        name: 'activity',
        meta: { title: '活动列表', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'edit',
        component: () => import('@/views/activity/edit.vue'),
        name: 'activity',
        hidden: true,
        meta: { title: '活动编辑', icon: 'el-icon-menu', noCache: true }
      },

    ]
  },
  // 发货管理
  {
    path: '/goods',
    redirect: '/goods/list',
    component: HomeView,
    hidden: false,
    meta: { title: '发货管理', icon: 'el-icon-goods', noCache: true },
    children: [
      {
        path: 'list',
        component: () => import('@/views/goods/index'),
        name: 'activity',
        meta: { title: '发货列表', icon: 'el-icon-s-order', noCache: true }
      }
    ]
  },
  // 测评管理
  {
    path: '/test',
    redirect: '/test/list',
    component: HomeView,
    meta: { title: '测评管理', icon: 'el-icon-menu', noCache: true },
    children: [
      // 测评征集列表
      {
        path: 'list',
        component: () => import('@/views/test/list/'),
        name: 'usertestList',
        meta: { title: '测评征集列表', icon: 'el-icon-menu', noCache: true },

      },
      // 征集文章编辑
      {
        path: 'edit',
        component: () => import('@/views/test/edit/'),
        name: 'testEdit',
        hidden: true,
        meta: { title: '测评征集文章', icon: 'el-icon-menu', noCache: false },
      },
      // 用户文章审核列表
      {
        path: 'user/ist',
        component: () => import('@/views/test/user/list'),
        name: 'userTestList',
        meta: { title: '用户文章审核', icon: 'el-icon-menu', noCache: false },
      },
      // 用户文章审核详情
      {
        path: 'user/detail',
        component: () => import('@/views/test/user/detail.vue'),
        name: 'userTestDetail',
        hidden: true,
        meta: { title: '测评征集文章', icon: 'el-icon-menu', noCache: false },
      },
    ]
  },
  // 广告管理
  {
    path: '/ad',
    redirect: '/ad/typeList',
    component: HomeView,
    meta: { title: '广告管理', icon: 'el-icon-menu', noCache: true },
    children: [
      // 广告分类列表
      {
        path: 'typeList',
        component: () => import('@/views/advertisement/type/list.vue'),
        name: 'adtypeList',
        meta: { title: '广告分类列表', icon: 'el-icon-menu', noCache: true },

      },
      // 广告分类编辑
      {
        path: 'typeEdit',
        component: () => import('@/views/advertisement/type/edit.vue'),
        name: 'adtypeEdit',
        hidden: true,
        meta: { title: '广告分类编辑', icon: 'el-icon-menu', noCache: false },
      },
      // 广告列表
      {
        path: 'list',
        component: () => import('@/views/advertisement/ad/list.vue'),
        name: 'adList',
        meta: { title: '广告列表', icon: 'el-icon-menu', noCache: false },
      },
      // 广告编辑
      {
        path: 'edit',
        component: () => import('@/views/advertisement/ad/edit.vue'),
        name: 'adEdit',
        hidden: true,
        meta: { title: '广告编辑', icon: 'el-icon-menu', noCache: false },
      },
    ]
  },
  // 工厂 
  {
    path: '/factory',
    redirect: '/factory/type',
    component: HomeView,
    hidden: false,
    meta: { title: '工厂管理', icon: 'el-icon-s-cooperation', noCache: true, type: 'factory' },
    children: [
      {
        path: 'type',
        component: () => import('@/views/factory/types'),
        name: 'factoryType',
        meta: { title: '工厂列表', icon: 'el-icon-s-order', noCache: true, type: 'factory' }
      },
      {
        path: 'typeEdit',
        redirect: '/factory/typeEdit/base',
        component: () => import('@/views/factory/types/edit'),
        name: 'factoryTypeEdit',
        hidden: true,
        meta: { title: '工厂编辑', icon: 'menu', noCache: true, type: 'factory' },
        children: [
          {
            path: 'base',
            component: () => import('@/views/factory/types/edit/base'),
            name: 'factoryTypeEditBase',
            hidden: true,
            meta: { title: '基础信息', icon: 'menu', noCache: true, type: 'factory' },
          },
          {
            path: 'images',
            component: () => import('@/views/factory/types/edit/images'),
            name: 'factoryTypeEditImages',
            hidden: true,
            meta: { title: '图片集', icon: 'menu', noCache: true, type: 'factory' },
          },
          {
            path: 'banner',
            component: () => import('@/views/factory/types/edit/banner'),
            name: 'factoryTypeEditBanner',
            hidden: true,
            meta: { title: '工厂banner', icon: 'menu', noCache: true, type: 'factory' },
          },
          {
            path: 'banner/edit',
            component: () => import('@/views/factory/types/edit/banner/edit'),
            name: 'factoryTypeEditBannerEdit',
            hidden: true,
            meta: { title: '工厂banner编辑', icon: 'menu', noCache: true, type: 'factory' },
          },
          {
            path: 'series',
            component: () => import('@/views/factory/types/edit/series'),
            name: 'factoryList',
            hidden: true,
            meta: { title: '工厂系列', icon: 'el-icon-s-order', noCache: true, type: 'factory' }
          },
          {
            path: 'seriesEdit',
            component: () => import('@/views/factory/types/edit/series/edit'),
            name: 'factorySeriesEdit',
            hidden: true,
            meta: { title: '工厂系列编辑', icon: 'el-icon-s-order', noCache: true, type: 'factory' }
          },
        ]

      },

    ]
  },
  // 方案商 
  {
    path: '/provider',
    redirect: '/provider/type',
    component: HomeView,
    hidden: false,
    meta: { title: '方案商管理', icon: 'el-icon-s-cooperation', noCache: true, type: 'provider' },
    children: [
      {
        path: 'type',
        component: () => import('@/views/factory/types'),
        name: 'providerType',
        meta: { title: '方案商列表', icon: 'el-icon-s-order', noCache: true, type: 'provider' }
      },
      {
        path: 'typeEdit',
        redirect: '/provider/typeEdit/base',
        component: () => import('@/views/factory/types/edit'),
        name: 'providerTypeEdit',
        hidden: true,
        meta: { title: '方案商编辑', icon: 'menu', noCache: true, type: 'provider' },
        children: [
          {
            path: 'base',
            component: () => import('@/views/factory/types/edit/base'),
            name: 'providerTypeEditBase',
            hidden: true,
            meta: { title: '基础信息', icon: 'menu', noCache: true, type: 'provider' },
          },
          {
            path: 'images',
            component: () => import('@/views/factory/types/edit/images'),
            name: 'providerTypeEditImages',
            hidden: true,
            meta: { title: '图片集', icon: 'menu', noCache: true, type: 'provider' },
          },
          {
            path: 'banner',
            component: () => import('@/views/factory/types/edit/banner'),
            name: 'providerTypeEditBanner',
            hidden: true,
            meta: { title: '方案商banner', icon: 'menu', noCache: true, type: 'provider' },
          },
          {
            path: 'banner/edit',
            component: () => import('@/views/factory/types/edit/banner/edit'),
            name: 'providerTypeEditBannerEdit',
            hidden: true,
            meta: { title: '方案商banner编辑', icon: 'menu', noCache: true, type: 'provider' },
          },
          {
            path: 'series',
            component: () => import('@/views/factory/types/edit/series'),
            name: 'providerList',
            hidden: true,
            meta: { title: '方案商系列', icon: 'el-icon-s-order', noCache: true, type: 'provider' }
          },
          {
            path: 'seriesEdit',
            component: () => import('@/views/factory/types/edit/series/edit'),
            name: 'providerSeriesEdit',
            hidden: true,
            meta: { title: '方案商系列编辑', icon: 'el-icon-s-order', noCache: true, type: 'provider' }
          },
        ]

      },

    ]
  },

  // 官方征集
  {
    path: '/solicitation',
    redirect: '/solicitation/list',
    component: HomeView,
    hidden: false,
    meta: { title: '官方征集管理', icon: 'el-icon-goods', noCache: true },
    children: [
      {
        path: 'list',
        component: () => import('@/views/solicitation/index'),
        name: 'solicitationList',
        meta: { title: '征集列表', icon: 'el-icon-s-order', noCache: true }
      },
      {
        path: 'edit',
        component: () => import('@/views/solicitation/edit.vue'),
        name: 'solicitationEdit',
        hidden: true,
        meta: { title: '征集编辑', icon: 'el-icon-menu', noCache: true }
      },

    ]
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  if (!to.path.includes('logIn')) {
    if (token) {
      next()
    } else {
      next({ path: '/logIn' })
    }
  } else {

    next()
  }

})

export default router